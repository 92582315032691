// Styles
import { FC } from 'react';
import {
  HeaderContainer,
  LangSwitchStartPage,
  NavContainer,
  NavLink,
  Wrap,
} from './style';

type PropsType = {
  mobileOpen: boolean;
};

export const StartPageHeader: FC<PropsType> = ({ mobileOpen }) => {
  return (
    <HeaderContainer className={mobileOpen ? 'open' : ''}>
      <Wrap>
        <NavContainer>
          <NavLink href="https://bhub.com.ua/">BEAUTY HUB</NavLink>
          <NavLink href="https://afisha.bhub.com.ua/">AFISHA</NavLink>
          <NavLink href="https://booking.bhub.com.ua/">BOOKING</NavLink>
        </NavContainer>
        <LangSwitchStartPage isStartPage />
      </Wrap>
    </HeaderContainer>
  );
};
