import styled from 'styled-components';
import { Logo } from '../layouts/Header/components/MainLogo/style';
import { whiteTheme } from '../styles/themes/whiteTheme';

export const Main = styled.main`
  display: flex;
  width: 100%;
  height: max-content;
  background-image: url('/images/pro-bhub-bg.webp');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  margin: 0 auto;
  @media screen and (min-height: 824px) {
    height: 100vh;
  }
  ${whiteTheme.media.mobileL} {
    background-color: #f6f6f6;
    background-image: none;
  }
  ${whiteTheme.media.mobileLM} {
    height: max-content;
    @media screen and (min-height: 831px) {
      height: 100vh;
    }
  }
`;

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  width: 100%;
  position: relative;
  margin: 0 auto;
  padding: 0 25px;
`;

export const LinkContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -12px;
`;

export const HeadContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 19px 0 27px;
  ${whiteTheme.media.mobileL} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const BhubTitle = styled.h1`
  width: 100%;
  display: flex;
  font-family: cormorant-garamond, serif;
  font-size: 22px;
  font-weight: 500;
  line-height: 24px;
  text-decoration: none;
  text-transform: uppercase;
  margin-bottom: 10px;
  ${whiteTheme.media.mobileL} {
    font-size: 20px;
  }
`;

export const BhubDescription = styled.p`
  width: 100%;
  display: flex;
  font-family: lato, sans-serif;
  font-size: 20px;
  font-weight: 300;
  line-height: 26px;
  ${whiteTheme.media.mobileL} {
    font-size: 16px;
  }
`;

export const BhubLogo = styled(Logo)`
  display: block;
  width: 179px;
  height: 43px;
  margin-right: 20px;
  margin-bottom: 25px;
  ${whiteTheme.media.tablet} {
    order: 0;
    margin-left: 0;
  }
  ${whiteTheme.media.mobileL} {
    width: 134px;
    min-height: 32px;
    height: 32px;
    position: relative;
    z-index: 10000;
    margin-bottom: 30px;
  }
`;

export const BurgerContainer = styled.button`
  width: 30px;
  height: 24px;
  position: absolute;
  top: 19px;
  right: 20px;
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  ${whiteTheme.media.mobileL} {
    display: flex;
  }
`;

export const BurgerItem = styled.span`
  width: 100%;
  height: 2px;
  background-color: red;
  position: relative;
  transition: 0.1s;
  &.open {
    background-color: transparent;
  }
  ::before {
    content: '';
    width: 100%;
    height: 2px;
    background-color: red;
    position: absolute;
    left: 0;
    top: -11px;
    transition: 0.3s;
  }
  &.open::before {
    transform: rotate(-45deg);
    top: 0;
  }
  ::after {
    content: '';
    width: 100%;
    height: 2px;
    background-color: red;
    position: absolute;
    left: 0;
    top: 11px;
    transition: 0.3s;
  }
  &.open::after {
    transform: rotate(45deg);
    top: 0;
  }
`;
