// Styles
import useTranslations from 'languages';
import {
  ProModulBackArrow,
  ProModulBackContainer,
  ProModulBackTitle,
  ProModulLink,
  ProModulLinkContainer,
} from './style';
import { useRouter } from 'next/router';

export const ProModuleItemBack = ({ href = '' }) => {
  const t = useTranslations();
  const { locale = '' } = useRouter();
  return (
    <ProModulLinkContainer>
      <ProModulLink href={href}>
        <ProModulBackContainer>
          <ProModulBackTitle>
            {t('backToBeautyHub')}
            <ProModulBackArrow className={locale == 'en' ? 'engLang' : ''} />
          </ProModulBackTitle>
        </ProModulBackContainer>
      </ProModulLink>
    </ProModulLinkContainer>
  );
};
