import styled from 'styled-components';

export const ImageWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  /* image-rendering: crisp-edges; */
  width: 200px;
  height: 100px;

  img {
    width: inherit;
    height: inherit;
    max-width: inherit;
    max-height: inherit;
  }
`;
