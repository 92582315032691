import { StartPage } from 'modules/main/StartPage';

// Api
import { QueryClient } from 'react-query';
import { dehydrate } from 'react-query/hydration';

export default StartPage;

export async function getStaticProps({ locale }: { locale: string }) {
  const queryClient = new QueryClient();

  return {
    props: {
      queryDehydratedState: dehydrate(queryClient),
      locale: locale,
      messages: require(`languages/${locale}/pages/startPage.ts`).startPage,
    },
    revalidate: 300,
  };
}
