import { whiteTheme } from 'modules/hairmakeup/styles/themes/whiteTheme';
import styled, { css } from 'styled-components';

export const LangContainer = styled.div`
  display: flex;
  margin-left: 70px;
  align-items: center;
  @media (max-width: 830px) {
    margin-left: 50px;
  }
  ${whiteTheme.media.tablet} {
    width: 100%;
    margin-bottom: 20px;
    margin-left: 0px;
    margin-right: -10px;
    :last-of-type {
      margin-bottom: 0;
    }
  }
`;

export const LangLink = styled.a`
  display: flex;
  width: 25px;
  height: 15px;
  font-family: lato, sans-serif;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 16px;
  color: #fff;
  background-color: transparent;
  margin-left: 10px;
  margin-right: 10px;
  padding: 0 3px 0 3px;
  cursor: pointer;
  text-decoration: none;
  :hover {
    color: inherit;
    text-decoration: underline;
  }
  ${({ $active }: any) =>
    $active &&
    css`
      background-color: #fff;
      color: #394285;
      :hover {
        color: #000;
        text-decoration: none;
      }
    `};

  ${whiteTheme.media.tablet} {
    width: auto;
    height: auto;
    color: #000;
    font-size: 21px;
    line-height: 27px;
    ${({ $active }: any) =>
      $active &&
      css`
        background-color: #fff;
        color: #394285;
        pointer-events: none;
      `};
  }
`;
