// Styles
import { useState } from 'react';
import { StartPageHeader } from './components/Header';
import { ModalWindow } from './components/ModalWindow';
import { ProModuleItem } from './components/ProModuleItem';
import {
  BhubDescription,
  BhubLogo,
  BhubTitle,
  BurgerContainer,
  BurgerItem,
  HeadContainer,
  LinkContainer,
  Main,
  Wrap,
} from './style';
import Head from 'next/head';
import Script from 'next/script';
import { useTranslations } from 'next-intl';
import { ProModuleItemBack } from './components/ProModuleItemBack';
import { useRouter } from 'next/router';

export const StartPage = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const t = useTranslations('');
  const { locale = '' } = useRouter();
  return (
    <>
      <Head>
        <title>{t('meta.title')}</title>
        <meta name="description" content={t('meta.title')} />
        <meta name="keywords" content={t('meta.description')} />
        <meta
          property="og:image"
          content="https://pro.bhub.com.ua/bhub_pro_logo_fb.png"
        ></meta>
        <meta
          name="twitter:image:src"
          content="https://pro.bhub.com.ua/bhub_pro_logo_fb.png"
        ></meta>

        <link rel="alternate" hrefLang="ru" href="/ru" />

        <link rel="alternate" hrefLang="uk" href="/" />

        <link rel="alternate" hrefLang="en" href="/en" />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="https://pro.bhub.com.ua/apple-touch-icon.png"
        ></link>
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="https://pro.bhub.com.ua/favicon-32x32.png"
        ></link>
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="https://pro.bhub.com.ua/favicon-16x16.png"
        ></link>
      </Head>
      <Script src="https://www.googletagmanager.com/gtag/js?id=G-RDCF1YMY2W" />
      <Script id="google-analytics">
        {`
           window.dataLayer = window.dataLayer || [];
           function gtag(){dataLayer.push(arguments);}
           gtag('js', new Date());
         
           gtag('config', 'G-RDCF1YMY2W');
        `}
      </Script>
      <StartPageHeader mobileOpen={mobileOpen} />
      <Main>
        <Wrap>
          <HeadContainer>
            <BhubLogo src="/images/bhub_pro_logo_edited.png" alt="logo"></BhubLogo>
            <BhubTitle>{t('mainTitle')}</BhubTitle>
            <BhubDescription>{t('mainDescription')}</BhubDescription>
          </HeadContainer>
          <BurgerContainer
            onClick={() => {
              setMobileOpen(!mobileOpen);
            }}
            type="button"
          >
            <BurgerItem className={mobileOpen ? 'open' : ''} />
          </BurgerContainer>
          <LinkContainer>
            <ProModuleItem
              href={`${locale === 'uk' ? '' : locale}/cosmetology`}
              src="/images/startPageProLogo/cosmetology.svg"
            />
            <ProModuleItem
              href={`${locale === 'uk' ? '' : locale}/permanent`}
              src="/images/startPageProLogo/permanent.svg"
            />
            <ProModuleItem
              href={`${locale === 'uk' ? '' : locale}/nails`}
              src="/images/startPageProLogo/nails.svg"
            />
            <ProModuleItem
              href={`${locale === 'uk' ? '' : locale}/hairmakeup`}
              src="/images/startPageProLogo/hairmakeup.svg"
            />
            <ProModuleItemBack href="https://bhub.com.ua/" />
          </LinkContainer>
        </Wrap>
        <ModalWindow />
      </Main>
    </>
  );
};

export default StartPage;
