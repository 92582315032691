import { LangSwitch } from 'modules/main/layouts/Header/components/TopMenu/LangSwich';
import styled, { css } from 'styled-components';
import { whiteTheme } from 'modules/main/styles/themes/whiteTheme';

export const HeaderContainer = styled.header`
  display: flex;
  width: 100%;
  background-color: #3a4185;
  ${whiteTheme.media.mobileL} {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #fff;
    z-index: 9999;
    transform: translateX(-100%);
    opacity: 0;
    transition: 0.3s;
  }
  &.open {
    transform: translateX(0);
    opacity: 1;
  }
`;

export const Wrap = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  height: 60px;
  ${whiteTheme.media.mobileL} {
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: 100px;
    flex-direction: column;
  }
`;

export const NavContainer = styled.nav`
  display: flex;
  ${whiteTheme.media.mobileL} {
    flex-direction: column;
  }
`;

export const NavLink = styled.a`
  display: flex;
  color: #fff;
  font-family: lato, sans-serif;
  font-size: 12px;
  font-weight: 300;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  margin-left: 20px;
  margin-right: 20px;
  ${whiteTheme.media.mobileL} {
    color: #3a4185;
    font-size: 16px;
    margin-bottom: 20px;
    font-weight: 400;
  }
`;

export const LangSwitchStartPage = styled(LangSwitch)`
  ${whiteTheme.media.tablet} {
    width: auto;
    margin: 0;
    a {
      font-size: 14px;
      color: #fff;
    }
    a.active {
      color: #394285;
    }
  }
  ${whiteTheme.media.mobileL} {
    margin-left: 10px;
    a {
      font-size: 14px;
      color: #394285;
    }
    a.active {
      background-color: #394285;
      color: #fff;
    }
  }
`;
