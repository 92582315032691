import styled from 'styled-components';
import { Link } from 'ui/Link';
import { Logo } from 'modules/main/layouts/Header/components/MainLogo/style';
import { whiteTheme } from 'styles/themes/whiteTheme';

export const ProModulLinkContainer = styled.div`
  float: left;
  margin: 12px;
  width: calc(25% - 24px);
  ${whiteTheme.media.tabletL} {
    width: calc(33.3% - 24px);
  }
  ${whiteTheme.media.mobileL} {
    width: calc(50% - 24px);
  }
`;

export const ProModulLink = styled.a`
  width: 100%;
  background: rgba(255, 255, 255, 0.4) no-repeat center;
  padding-top: 100%;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
export const ProLogo = styled(Logo).attrs(() => ({
  objectFit: 'contain',
}))`
  width: 120px;
  height: 120px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.2s;
  :hover {
    transform: translate(-50%, -50%) scale(1.1, 1.1);
  }
  ${whiteTheme.media.mobileL} {
    width: 100px;
    height: 100px;
  }
`;
