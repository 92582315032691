// Styles
import { useEffect, useState } from 'react';
import {
  ModalAgree,
  ModalAgreeButton,
  ModalBackArrow,
  ModalBackButton,
  ModalBody,
  ModalDialog,
  ModalRemember,
  ModalRememberInput,
  ModalRememberLabel,
  ModalTitle,
  ModalWindowContainer,
} from './style';
import { getBrowserStorage } from 'plugins/localForage';
import useTranslations from 'languages';

export const ModalWindow = () => {
  const mainStorage = getBrowserStorage();

  const [isAgree, setAgree] = useState(false);
  const [isOpen, setOpen] = useState(isAgree);
  useEffect(() => {
    mainStorage.setItem('remember_me', isAgree);
  }, [isAgree]);

  const getRememberMeFromStorege = async () => {
    const rememberMe = await mainStorage.getItem('remember_me');
    setAgree(rememberMe);
  };

  useEffect(() => {
    getRememberMeFromStorege();
  }, []);

  const t = useTranslations();

  return (
    <ModalWindowContainer className={isOpen ? '' : 'close'}>
      <ModalDialog>
        <ModalTitle>{t('modalWindowTitle')}</ModalTitle>
        <ModalBody>
          <ModalAgree>{t('agreement')}</ModalAgree>
          <ModalAgreeButton
            type="button"
            onClick={() => {
              setOpen(!isOpen);
            }}
          >
            {t('continueWatch')}
          </ModalAgreeButton>
          <ModalBackButton>
            <ModalBackArrow src="/icons/ico_arrow_b.png" />
            <span>{t('back')}</span>
          </ModalBackButton>
          <ModalRemember>
            <ModalRememberInput
              type="checkbox"
              onChange={() => {
                setAgree(!isAgree);
              }}
              id="ispetsialist-aggry-check"
            />
            <ModalRememberLabel htmlFor="ispetsialist-aggry-check">
              {t('rememberMe')}
            </ModalRememberLabel>
          </ModalRemember>
        </ModalBody>
      </ModalDialog>
    </ModalWindowContainer>
  );
};
