import NextImage from 'next/image';
import React, { forwardRef, useMemo } from 'react';
import { ImageWrapper } from './style';

type ImageType = {
  width?: number;
  height?: number;
  alt?: string;
  className?: string;
  layout?: 'fixed' | 'fill' | 'intrinsic' | 'responsive';
  objectFit?: any;
  objectPosition?: any;
  placeholder?: any;
  blurDataURL?: any;
  loading?: any;
  priority?: boolean;
  quality?: number;
  sizes?: string;

  path?: string;
  dir?: string;
  subDir?: string;
  name?: string;
  ext?: string;
  fullPath?: string;
  src?: string;

  activeDir?: string;
  activeName?: string;
  activePath?: string;
  activeExt?: string;
  fullActivePath?: string;

  onError?: (event: any) => void;
  children?: any;
  active?: boolean;
};

export const Image = React.memo<ImageType>(
  forwardRef(
    (
      {
        width = 200,
        height = 200,
        className = '',
        layout = 'fill',
        objectFit = 'contain',
        objectPosition,
        quality,
        sizes,
        priority,
        path = '',
        dir = '/images',
        subDir = dir === '/' ? '' : '/',
        name = 'image',
        ext = '',
        fullPath = `${path ? path : `${dir}${subDir}${name}${ext}`}`,
        src = '',

        activeDir = dir,
        activeName = '',
        activePath = '',
        activeExt = '',
        fullActivePath = `${
          activePath ? activePath : `${activeDir}/${activeName}${activeExt}`
        }`,

        active = false,
        ...props
      },
      ref?: any
    ) => {
      const sizeProps = useMemo(() => {
        if (layout === 'fill') {
          return {
            objectFit,
            objectPosition,
          };
        }

        let intWidth = width;
        let intHeight = height;

        return {
          width: isNaN(intWidth) ? width : intWidth,
          height: isNaN(intHeight) ? height : intHeight,
        };
      }, [width, height, layout, objectFit, objectPosition]);

      const activeSrc = activeName || activePath ? fullActivePath : '';

      return (
        <ImageWrapper className={className} ref={ref}>
          <NextImage
            src={active ? activeSrc : src || fullPath}
            alt={name}
            layout={layout}
            quality={80}
            // sizes={sizes}
            sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
            priority={priority}
            {...sizeProps}
            {...props}
          />
          {props.children}
        </ImageWrapper>
      );
    }
  )
);
