// Styles
import { useStore } from 'core/GlobalStore';
import { LangContainer, LangLink } from './style';

// Hooks
import { RU_LANGUAGE, UK_LANGUAGE, US_LANGUAGE } from 'languages';
import { useRouter } from 'next/router';
import { SeoType } from 'types/api/itemsResponseType';

type DataHeaderType = {
  articleMetaData?: SeoType;
  logo?: string;
  modul_id?: number;
};

export const LangSwitch = ({
  isArticle,
  isStartPage,
  dataForHeader = {},
  isColumnist,
  ...props
}: {
  isArticle?: boolean;
  isStartPage?: boolean;
  dataForHeader?: DataHeaderType;
  isColumnist?: boolean;
}) => {
  const { asPath = '', locale = '' } = useRouter();

  const { langLinks: langLinks = {} } = useStore('langModel')('langLinks');

  const {
    articleMetaData = {
      title_en: '',
      title_ru: '',
      title_uk: '',
    },
  } = dataForHeader;

  const { title_en = '', title_ru = '', title_uk = '' } = articleMetaData;

  const asPathWithoutSlug = asPath?.split?.('/');

  const pathArticle = isArticle
    ? asPathWithoutSlug.slice(0, -1).join('/')
    : asPathWithoutSlug.join('/');

  const linkUkForColumnist =
    title_uk || title_uk?.length !== 0
      ? `/${asPathWithoutSlug[1]}/columnist/${articleMetaData.slug_uk}`
      : `/${asPathWithoutSlug[1]}/columnist`;

  const linkEnForColumnist =
    title_en || title_en?.length !== 0
      ? `/en/${asPathWithoutSlug[1]}/columnist/${articleMetaData.slug_en}`
      : `/en/${asPathWithoutSlug[1]}/columnist`;

  const linkRuForColumnist =
    title_ru || title_ru?.length !== 0
      ? `/ru/${asPathWithoutSlug[1]}/columnist/${articleMetaData.slug_uk}`
      : `/ru/${asPathWithoutSlug[1]}/columnist`;

  return (
    <LangContainer id="Lang" {...props}>
      <LangLink
        href={
          locale === UK_LANGUAGE
            ? ''
            : title_uk?.length === 0 || !title_uk
            ? pathArticle
            : isColumnist
            ? linkUkForColumnist
            : langLinks[UK_LANGUAGE] || asPath
        }
        // @ts-ignore
        $active={locale === UK_LANGUAGE}
        className={locale === UK_LANGUAGE ? 'active' : ''}
      >
        {UK_LANGUAGE.toUpperCase()}
      </LangLink>
      <LangLink
        href={
          locale === RU_LANGUAGE
            ? `/${RU_LANGUAGE}`
            : title_ru?.length === 0 || !title_ru
            ? `/${RU_LANGUAGE}${pathArticle}`
            : isColumnist
            ? linkRuForColumnist
            : `/${RU_LANGUAGE}${langLinks[RU_LANGUAGE] || asPath}`
        }
        // @ts-ignore
        $active={locale === RU_LANGUAGE}
        className={locale === RU_LANGUAGE ? 'active' : ''}
      >
        {RU_LANGUAGE.toUpperCase()}
      </LangLink>
      <LangLink
        href={
          locale === US_LANGUAGE
            ? `/${US_LANGUAGE}`
            : title_en?.length === 0 || !title_en
            ? `/${US_LANGUAGE}${pathArticle}`
            : isColumnist
            ? linkEnForColumnist
            : `/${US_LANGUAGE}${langLinks[US_LANGUAGE] || asPath}`
        }
        // @ts-ignore
        $active={locale === US_LANGUAGE}
        className={locale === US_LANGUAGE ? 'active' : ''}
      >
        {US_LANGUAGE.toUpperCase()}
      </LangLink>
    </LangContainer>
  );
};

export default LangSwitch;
