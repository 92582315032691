import styled from 'styled-components';
import { Link } from 'ui/Link';
import { Logo } from 'modules/main/layouts/Header/components/MainLogo/style';
import { Image } from 'ui/Image';

export const ModalWindowContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  display: block;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  outline: 0;
  background: rgba(255, 255, 255, 0.95);
  &.close {
    display: none;
    overflow: hidden;
  }
`;

export const ModalDialog = styled.div`
  top: 20%;
  max-width: 600px;
  position: relative;
  width: auto;
  margin: 30px auto;
`;

export const ModalTitle = styled.h4`
  display: block;
  position: relative;
  width: 100%;
  text-align: center;
  font-size: 60px;
  font-family: lato, sans-serif;
  font-style: italic;
  line-height: 60px;
  color: #3a4185;
  margin-top: 0;
  margin-bottom: 37px;
  font-weight: 500;
  ::before,
  ::after {
    content: '';
    width: 30px;
    height: 1px;
    display: inline-block;
    position: relative;
    line-height: 60px;
    background: #3a4185;
    vertical-align: middle;
    margin-top: -10px;
  }
  ::before {
    margin-right: 10px;
  }
  ::after {
    margin-left: 10px;
  }
`;

export const ModalBody = styled.div`
  position: relative;
  padding: 12px;
`;

export const ModalAgree = styled.p`
  font-size: 18px;
  line-height: 1.3;
  display: block;
  color: #1f1f1f;
  font-family: lato, sans-serif;
  font-weight: 400;
  text-align: center;
  max-width: 360px;
  margin: 0 auto;
`;

export const ModalAgreeButton = styled.button`
  text-align: center;
  margin: 40px auto 60px auto;
  max-width: 100%;
  width: auto;
  border-color: transparent;
  display: block;
  position: relative;
  border: 2px solid #fff;
  height: 42px;
  font-family: lato, sans-serif;
  line-height: 40px;
  padding: 0 25px;
  color: #d73e4a;
  font-size: 20px;
  vertical-align: top;
  text-decoration: none;
  cursor: pointer;
`;

export const ModalBackButton = styled(Link)`
  display: flex;
  align-items: center;
  float: left;
  margin-left: 12px;
  color: #222222;
  font-size: 16px;
  font-weight: 300;
  width: auto;
  text-decoration: none;
  cursor: pointer;
`;

export const ModalBackArrow = styled(Image)`
  display: block;
  float: left;
  width: 28px;
  height: 19px;
  margin-right: 10px;
  margin-top: -1px;
`;

export const ModalRemember = styled.div`
  display: block;
  float: right;
  margin-left: 12px;
  width: auto;
  & input {
    display: none;
    :checked + label:before {
      color: #3a4185;
    }
  }
  & label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    color: #222222;
    font-size: 16px;
    font-weight: 300;
    ::before {
      content: '✓';
      color: transparent;
      display: inline-block;
      border: 1px solid #3a4185;
      font-size: 18px;
      line-height: 20px;
      margin: -5px 10px 0 0;
      height: 18px;
      width: 18px;
      text-align: center;
      vertical-align: middle;
      transition: color ease 0.3s;
      cursor: pointer;
    }
  }
`;

export const ModalRememberInput = styled.input``;

export const ModalRememberLabel = styled.label``;
