import styled from 'styled-components';
import { Link } from 'ui/Link';
import { Logo } from 'modules/main/layouts/Header/components/MainLogo/style';
import { whiteTheme } from 'styles/themes/whiteTheme';

export const ProModulLinkContainer = styled.div`
  float: left;
  margin: 12px;
  width: calc(25% - 24px);
  ${whiteTheme.media.tabletL} {
    width: calc(33.3% - 24px);
  }
  ${whiteTheme.media.mobileL} {
    width: calc(50% - 24px);
  }
`;

export const ProModulLink = styled.a`
  width: 100%;
  background: rgba(255, 255, 255, 0.4) no-repeat center;
  padding-top: 100%;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const ProModulBackContainer = styled.div`
  width: 128px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.2s;
  :hover {
    transform: translate(-50%, -50%) scale(1.1, 1.1);
  }
  ${whiteTheme.media.mobileL} {
    width: 100px;
    height: 100px;
  }
`;

export const ProModulBackTitle = styled.p`
  width: 100%;
  font-family: lato, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  text-decoration: none;
  color: #3a4185;
  ${whiteTheme.media.mobileL} {
    font-size: 13px;
  }
`;

export const ProModulBackArrow = styled.span`
  display: flex;
  position: absolute;
  width: 20px;
  height: 1px;
  background-color: #3a4185;
  bottom: 10px;
  right: 22px;
  &.engLang {
    right: 70px;
  }
  ::before {
    content: '';
    width: 10px;
    height: 1px;
    position: absolute;
    top: 4px;
    right: -2px;
    background-color: #3a4185;
    transform: rotate(-50deg);
  }
  ::after {
    content: '';
    width: 10px;
    height: 1px;
    position: absolute;
    top: -4px;
    right: -2px;
    background-color: #3a4185;
    transform: rotate(50deg);
  }
  ${whiteTheme.media.mobileL} {
    left: 0;
    &.engLang {
      bottom: 38px;
      right: 45px;
      left: auto;
    }
  }
`;
