// Styles
import { ProLogo, ProModulLink, ProModulLinkContainer } from './style';

export const ProModuleItem = ({ href = '', src = '' }) => {
  return (
    <ProModulLinkContainer>
      <ProModulLink href={href}>
        <ProLogo src={src} alt="proLogo" />
      </ProModulLink>
    </ProModulLinkContainer>
  );
};
